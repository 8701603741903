import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AdminLayout from "./components/AdminLayout";
import HomePage from "./pages/HomePage";
import AdminPage from "./pages/admin/AdminPage";
import ItemsPage from "./pages/admin/ItemsPage";
import LoginPage from "./pages/admin/LoginPage";
import MenuPage from "./pages/admin/MenuPage";
import OrdersPage from "./pages/admin/OrdersPage";
import SettingsPage from "./pages/admin/SettingsPage";
import TradingPage from "./pages/admin/TradingPage";
import { setNavigateFunction } from "./utils/Navigation";

const App: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      {/* Admin routes */}
      <Route path="/admin" element={<AdminLayout />}>
        <Route index element={<AdminPage />} />
        <Route path="orders" element={<OrdersPage />} />
        <Route path="trading" element={<TradingPage />} />
        <Route path="menu" element={<MenuPage />} />
        <Route path="items" element={<ItemsPage />} />
        <Route path="settings" element={<SettingsPage />} />
        {/* Add more nested routes for admin as needed */}
      </Route>
    </Routes>
  );
};

export default App;
