import styled from "@emotion/styled";
import BigNumber from "bignumber.js";
import React, { useState } from "react";

const ReviewOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const OrderSummaryContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const OrderItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
`;

const OrderItemDetails = styled.div`
  text-align: left;
`;

const OrderItemName = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
`;

const OrderItemDescription = styled.div`
  font-size: 0.85rem;
  color: #666;
  padding-right: 10px;
`;

const OrderItemQuantityPrice = styled.div`
  font-size: 1rem;
  color: #000;
`;

const TotalPrice = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
  margin-top: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
`;

const Button = styled.button`
  background: #51535a;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 48%;

  &:hover {
    background: #6b6d74;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 0.85rem;
  margin-top: 0.25rem;
`;

interface ReviewOrderFormProps {
  order: {
    tradingDayId: string;
    tradingSlotId: string;
    items: {
      menuItemId: string;
      name: string;
      description: string;
      quantity: number;
      price: string;
    }[];
    total: string;
    name?: string;
    email?: string;
    phone?: string;
    notes?: string;
  };
  totalPrice: number;
  timeSlotDate: string;
  onConfirm: (updatedOrder: {
    tradingDayId: string;
    tradingSlotId: string;
    items: {
      menuItemId: string;
      name: string;
      description: string;
      quantity: number;
      price: string;
    }[];
    total: string;
    name: string;
    email: string;
    phone: string;
    notes?: string;
  }) => void;
  onEdit: () => void;
}

const ReviewOrderForm: React.FC<ReviewOrderFormProps> = ({
  order,
  totalPrice,
  timeSlotDate,
  onConfirm,
  onEdit,
}) => {
  const [name, setName] = useState(order.name || "");
  const [email, setEmail] = useState(order.email || "");
  const [phone, setPhone] = useState(order.phone || "");
  const [notes, setNotes] = useState(order.notes || "");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const orderedItems = order.items.filter((item) => item.quantity > 0);

  const handleConfirm = () => {
    let hasError = false;
    if (!name) {
      setNameError("Name is required");
      hasError = true;
    } else {
      setNameError("");
    }
    if (!email) {
      setEmailError("Email is required");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (!phone) {
      setPhoneError("Phone is required");
      hasError = true;
    } else {
      setPhoneError("");
    }

    if (hasError) {
      return;
    }

    onConfirm({
      ...order,
      name,
      email,
      phone,
      notes,
    });
  };

  return (
    <ReviewOrderContainer>
      <Header>
        <h2>Review Your Order</h2>
        <p>Time Slot: {timeSlotDate}</p>
      </Header>
      <FormGroup>
        <Label htmlFor="name">Name *</Label>
        <Input
          type="text"
          id="name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            if (e.target.value) setNameError("");
          }}
          required
        />
        {nameError && <ErrorMessage>{nameError}</ErrorMessage>}
      </FormGroup>
      <FormGroup>
        <Label htmlFor="email">Email *</Label>
        <Input
          type="email"
          id="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            if (e.target.value) setEmailError("");
          }}
          required
        />
        {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
      </FormGroup>
      <FormGroup>
        <Label htmlFor="phone">Phone *</Label>
        <Input
          type="text"
          id="phone"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
            if (e.target.value) setPhoneError("");
          }}
          required
        />
        {phoneError && <ErrorMessage>{phoneError}</ErrorMessage>}
      </FormGroup>
      <FormGroup>
        <Label htmlFor="notes">Notes</Label>
        <Input
          type="text"
          id="notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </FormGroup>
      <OrderSummaryContainer>
        {orderedItems.map((item) => (
          <OrderItemContainer key={item.menuItemId}>
            <OrderItemDetails>
              <OrderItemName>{item.name}</OrderItemName>
              <OrderItemDescription>{item.description}</OrderItemDescription>
            </OrderItemDetails>
            <OrderItemQuantityPrice>
              {item.quantity} x £{new BigNumber(item.price).toFixed(2)}
            </OrderItemQuantityPrice>
          </OrderItemContainer>
        ))}
        <TotalPrice>Total: £{totalPrice.toFixed(2)}</TotalPrice>
      </OrderSummaryContainer>
      <ButtonContainer>
        <Button onClick={onEdit}>Add More</Button>
        <Button onClick={handleConfirm}>Confirm Order</Button>
      </ButtonContainer>
    </ReviewOrderContainer>
  );
};

export default ReviewOrderForm;
