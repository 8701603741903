import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { navigateToLogin } from "../utils/Navigation";

interface ErrorResponseData {
  message?: string;
}

// Create Axios instance with base URL
const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response) {
      const data = error.response.data as ErrorResponseData;

      if (error.response.status === 401 || data?.message === "Token expired") {
        localStorage.removeItem("authToken");
        navigateToLogin();
      }
    }

    return Promise.reject(error);
  }
);

const getAuthToken = () => {
  return localStorage.getItem("authToken");
};

const getRequestConfig = () => {
  const token = getAuthToken();
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
};

export const useApi = () => {
  const login = async (username: string, password: string) => {
    try {
      const response = await api.post(
        "/admin/auth/login",
        { username, password },
        getRequestConfig() // Include auth header here
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to login");
    }
  };

  const changePassword = async (password: string, newPassword: string) => {
    try {
      const response = await api.post(
        "/admin/auth/change-password",
        { password, newPassword },
        getRequestConfig()
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to change password");
    }
  };

  const userInfo = async () => {
    try {
      const response = await api.get("/admin/user/info", getRequestConfig());

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch user info");
    }
  };

  const addUser = async (formData: FormData) => {
    try {
      const response = await api.post(
        "/admin/user",
        formData,
        getRequestConfig()
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to add user");
    }
  };

  const getItems = async () => {
    try {
      const response = await api.get("/admin/menu/items", getRequestConfig());
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch items");
    }
  };

  const addItem = async (formData: {
    name: string;
    description: string;
    price: string;
  }) => {
    try {
      const response = await api.post(
        "/admin/menu/item",
        formData,
        getRequestConfig()
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to add item");
    }
  };

  const updateItem = async (itemId: string, formData: any) => {
    try {
      const response = await api.patch(
        `/admin/menu/item/${itemId}`,
        formData,
        getRequestConfig()
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to update item");
    }
  };

  const fetchMenus = async () => {
    try {
      const response = await api.get("/admin/menu", getRequestConfig());
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch menus");
    }
  };

  const getMenu = async (
    menuId: string
  ): Promise<{
    menuId: string;
    name: string;
    items: {
      itemId: string;
      menuItemId: string;
      name: string;
      description: string;
      price: string;
      order: number;
    }[];
  }> => {
    try {
      const response = await api.get(
        `/admin/menu/${menuId}`,
        getRequestConfig()
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch menu");
    }
  };

  const addMenu = async (menu: {
    name: string;
    items: { itemId: string; order: number }[];
  }) => {
    try {
      const response = await api.post("/admin/menu", menu, getRequestConfig());

      return response.data;
    } catch (error) {
      throw new Error("Failed to add menu");
    }
  };

  const updateMenu = async (
    menuId: string,
    menu: {
      name: string;
      items: { itemId: string; order: number }[];
    }
  ) => {
    try {
      const response = await api.patch(
        `/admin/menu/${menuId}`,
        menu,
        getRequestConfig()
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to add menu");
    }
  };

  const fetchTradingLocations = async () => {
    try {
      const response = await api.get(
        `/admin/trade/locations`,

        getRequestConfig()
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch trading locations");
    }
  };

  const addTradingDay = async (formData: {
    date: string;
    location: string;
    menu: string;
    start: string;
    close: string;
    tradesPerSlot: number;
  }) => {
    try {
      const response = await api.post(
        "/admin/trade/day",
        formData,
        getRequestConfig()
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to add trading day");
    }
  };

  const fetchTradingDays = async (
    page: number,
    limit: number
  ): Promise<{
    page: number;
    totalPages: number;
    totalItems: number;
    items: {
      tradingDayId: string;
      date: string;
      tradingLocationId: string;
      tradingLocationName: string;
      menuId: string;
      start: string;
      close: string;
    }[];
  }> => {
    try {
      const response = await api.get("/trade/days", {
        params: { page, limit },
      });

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch trading days");
    }
  };

  // fetch admin trading days list
  const fetchTradingDaysList = async (): Promise<
    {
      tradingDayId: string;
      date: string;
    }[]
  > => {
    try {
      const response = await api.get("/admin/trade/days", getRequestConfig());

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch admin trading days");
    }
  };

  const fetchTradingDayTimeSlots = async (
    tradingDayId: string
  ): Promise<
    {
      tradingSlotId: string;
      start: string;
      end: string;
      capacity: number;
      remaining: number;
      number: number;
    }[]
  > => {
    try {
      const response = await api.get(`/trade/days/${tradingDayId}/slots`);

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch trading day time slots");
    }
  };

  // fetch trading slots remaining capacity
  const fetchTradingSlotCapacity = async (
    tradingSlotId: string
  ): Promise<{
    tradingSlotId: string;
    capacity: number;
    remaining: number;
  }> => {
    try {
      const response = await api.get(`/trade/days/${tradingSlotId}/capacity`);

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch trading slot capacity");
    }
  };

  // post order
  const postOrder = async (order: {
    tradingDayId: string;
    tradingSlotId: string;
    name: string;
    email: string;
    phone: string;
    total: string;
    notes?: string;
    items: { menuItemId: string; quantity: number; price: string }[];
  }): Promise<{ orderNumber: number }> => {
    try {
      const response = await api.post("/orders/", order);

      return response.data;
    } catch (error) {
      throw new Error("Failed to submit order");
    }
  };

  // get orders
  const fetchOrdersOrdersByTradingDay = async (
    tradingDayId: string,
    page: number,
    limit: number,
    name?: string,
    orderNumber?: string,
    status?: string
  ): Promise<{
    page: number;
    totalPages: number;
    totalItems: number;
    items: {
      orderNumber: string;
      timeSlot: string;
      name: string;
      email: string;
      phone: string;
      total: string;
      notes?: string;
      status: string;
      items: { name: string; quantity: number; price: string }[];
    }[];
  }> => {
    try {
      const config = {
        ...getRequestConfig(),
        params: { page, limit, name, orderNumber, status },
      };

      const response = await api.get(`/admin/orders/${tradingDayId}`, config);

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch orders");
    }
  };

  // fetch order count by trading day
  const fetchOrdersCountByTradingDay = async (
    tradingDayId: string,
    status: string
  ): Promise<{ orders: number; pizzas: number }> => {
    try {
      const response = await api.get(
        `/admin/orders/${tradingDayId}/count/${status}`,
        getRequestConfig()
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch orders count");
    }
  };

  const updateOrderStatus = async (
    orderNumber: number,
    status: string
  ): Promise<void> => {
    try {
      const config = {
        ...getRequestConfig(),
      };

      const response = await api.patch(
        `/admin/orders/${orderNumber}/status`,
        { status },
        config
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to update order");
    }
  };

  return {
    login,
    changePassword,
    userInfo,
    addUser,
    getItems,
    addItem,
    updateItem,
    fetchMenus,
    addMenu,
    updateMenu,
    getMenu,
    fetchTradingLocations,
    addTradingDay,
    fetchTradingDays,
    fetchTradingDayTimeSlots,
    fetchTradingSlotCapacity,
    postOrder,
    fetchTradingDaysList,
    fetchOrdersOrdersByTradingDay,
    fetchOrdersCountByTradingDay,
    updateOrderStatus,
    // Add other API methods here
  };
};
