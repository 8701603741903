import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { useApi } from "../hooks/useApi";
import { Item, Menu, MenuItem } from "../types";

interface AddMenuFormProps {
  items: Item[];
  onSubmit: (menu: Menu) => void;
  menu: Menu | null;
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 800px; /* Fixed max width for the form */
  margin: 0 auto; /* Center the form horizontally */
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const TextInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ItemsTitle = styled.h3`
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
`;

const ItemsContainer = styled.div`
  display: grid;
  gap: 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Adjusted for 3 columns on desktop */
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr); /* Single column on mobile */
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 300px; /* Set a fixed height */
  overflow-y: auto; /* Allow vertical scrolling if content overflows */
`;

const ItemCard = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: ${({ isSelected }) => (isSelected ? "row" : "column")};
  justify-content: ${({ isSelected }) =>
    isSelected ? "space-between" : "center"};
  align-items: center;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: ${({ isSelected }) => (isSelected ? "#e0f7fa" : "#fff")};
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0f7fa;
  }
`;

const ItemName = styled.p`
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
`;

const ItemPrice = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: #666;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  align-self: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const AddMenuForm: React.FC<AddMenuFormProps> = ({
  items = [],
  onSubmit,
  menu = null,
}) => {
  const { addMenu } = useApi();
  const [name, setName] = useState(menu?.name ?? "");
  const [selectedItems, setSelectedItems] = useState<MenuItem[]>(
    menu?.items ?? []
  );
  const [availableItems, setAvailableItems] = useState<Item[]>([]);

  useEffect(() => {
    if (menu) {
      setName(menu.name);
      setSelectedItems(menu.items);
    }
  }, [menu]);

  useEffect(() => {
    const selectedIds = new Set(selectedItems.map((item) => item.itemId));
    setAvailableItems(items.filter((item) => !selectedIds.has(item.itemId)));
  }, [items, selectedItems]);

  const handleItemChange = (item: Item) => {
    const isSelected = selectedItems.some((i) => i.itemId === item.itemId);
    if (isSelected) {
      setSelectedItems((prevItems) =>
        prevItems.filter((i) => i.itemId !== item.itemId)
      );
      setAvailableItems((prevItems) => [...prevItems, item]);
    } else {
      const newItem: MenuItem = { ...item, order: selectedItems.length };
      setSelectedItems((prevItems) => [...prevItems, newItem]);
      setAvailableItems((prevItems) =>
        prevItems.filter((i) => i.itemId !== item.itemId)
      );
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(selectedItems);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setSelectedItems(
      reorderedItems.map((item, index) => ({ ...item, order: index }))
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newMenu: Menu = {
      menuId: menu ? menu.menuId : uuidv4(), // Generate a new menuId if not provided
      name,
      items: selectedItems.map((item) => ({
        itemId: item.itemId,
        name: item.name,
        description: item.description,
        price: item.price,
        order: item.order,
      })),
    };

    await addMenu({
      name,
      items: selectedItems.map((item) => ({
        itemId: item.itemId,
        order: item.order,
      })),
    });

    onSubmit(newMenu);
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <InputContainer>
        <Label>Menu Name:</Label>
        <TextInput
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </InputContainer>
      <ItemsSection>
        <ItemsTitle>Selected Items</ItemsTitle>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={uuidv4()}>
            {(provided) => (
              <ListContainer
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {selectedItems.map(
                  (item, index) =>
                    item.itemId !== undefined && (
                      <Draggable
                        key={item.itemId.toString()}
                        draggableId={item.itemId.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <ItemCard
                            isSelected={true}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => handleItemChange(item)}
                          >
                            <ItemName>{item.name}</ItemName>
                            <ItemPrice>£{item.price}</ItemPrice>
                          </ItemCard>
                        )}
                      </Draggable>
                    )
                )}
                {provided.placeholder}
              </ListContainer>
            )}
          </Droppable>
        </DragDropContext>
      </ItemsSection>
      <ItemsSection>
        <ItemsTitle>Available Items</ItemsTitle>
        <ItemsContainer>
          {availableItems.map((item) => (
            <ItemCard
              key={item.itemId}
              isSelected={false}
              onClick={() => handleItemChange(item)}
            >
              <ItemName>{item.name}</ItemName>
              <ItemPrice>£{item.price}</ItemPrice>
            </ItemCard>
          ))}
        </ItemsContainer>
      </ItemsSection>
      <SubmitButton type="submit">
        {menu ? "Update Menu" : "Add Menu"}
      </SubmitButton>
    </FormContainer>
  );
};

export default AddMenuForm;
