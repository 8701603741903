/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { faPizzaSlice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const PizzaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
`;

const PizzaIcon = styled(FontAwesomeIcon)`
  font-size: 4rem;
  animation: ${spin} 2s linear infinite;
  color: #51535a;
`;

const LoadingPizza: React.FC = () => {
  return (
    <PizzaContainer>
      <PizzaIcon icon={faPizzaSlice} />
    </PizzaContainer>
  );
};

export default LoadingPizza;
