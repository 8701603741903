// utils/navigation.ts
import { NavigateFunction } from "react-router-dom";

let navigateFunction: NavigateFunction | null = null;

export const setNavigateFunction = (navigate: NavigateFunction): void => {
  navigateFunction = navigate;
};

export const navigateToLogin = (): void => {
  if (navigateFunction) {
    navigateFunction("/login");
  } else {
    window.location.href = "/login"; // Fallback if navigate is not set
  }
};
