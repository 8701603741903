// AdminPage.tsx
import styled from "@emotion/styled";
import React from "react";

const AdminPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const AdminPage: React.FC = () => {
  return (
    <AdminPageContainer>
      <h1>Admin Page</h1>
      <p>Welcome to the admin page!</p>
    </AdminPageContainer>
  );
};

export default AdminPage;
