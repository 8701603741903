import styled from "@emotion/styled";
import React from "react";

const ConfirmedOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  margin: 2rem auto;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const OrderSummaryContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const OrderItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
`;

const OrderItemDetails = styled.div`
  text-align: left;
`;

const OrderItemName = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
`;

const OrderItemDescription = styled.div`
  font-size: 0.85rem;
  color: #666;
  padding-right: 10px;
`;

const OrderItemQuantityPrice = styled.div`
  font-size: 1rem;
  color: #000;
`;

const TotalPrice = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
  margin-top: 1rem;
  color: #333;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const Info = styled.div`
  width: 100%;
  padding: 1rem;
  margin-bottom: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const InfoItem = styled.p`
  margin: 0.5rem 0;
  text-align: left;
  color: #333;
`;

const HighlightedText = styled.span`
  font-weight: bold;
  color: #51535a;
`;

interface OrderItem {
  menuItemId: string;
  name: string;
  description: string;
  quantity: number;
  price: string;
}

interface Order {
  name: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  notes?: string;
  items: OrderItem[];
  total: string;
}

interface ConfirmedOrderFormProps {
  order: Order;
  timeSlotDate: string;
  orderNumber: number;
}

const ConfirmedOrderForm: React.FC<ConfirmedOrderFormProps> = ({
  order,
  timeSlotDate,
  orderNumber,
}) => {
  const orderedItems = order.items.filter((item) => item.quantity > 0);

  return (
    <ConfirmedOrderContainer>
      <Header>
        <h2>Order Confirmed</h2>
        <p>Thank you for your order!</p>
        <p>
          Your order number is:{" "}
          <HighlightedText>#{orderNumber}</HighlightedText>
        </p>
        <p>
          You will receive and email shortly with confirmation, if you do not
          see this please check junk/spam folder.
        </p>
      </Header>
      <Info>
        <InfoItem>
          <strong>Name:</strong> {order.name}
        </InfoItem>
        <InfoItem>
          <strong>Email:</strong> {order.email}
        </InfoItem>
        <InfoItem>
          <strong>Phone:</strong> {order.phone}
        </InfoItem>
        <InfoItem>
          <strong>Notes:</strong> {order.notes}
        </InfoItem>
      </Info>
      <OrderSummaryContainer>
        {orderedItems.map((item) => (
          <OrderItemContainer key={item.menuItemId}>
            <OrderItemDetails>
              <OrderItemName>{item.name}</OrderItemName>
              <OrderItemDescription>{item.description}</OrderItemDescription>
            </OrderItemDetails>
            <OrderItemQuantityPrice>
              {item.quantity} x £{item.price}
            </OrderItemQuantityPrice>
          </OrderItemContainer>
        ))}
        <TotalPrice>Total: £{order.total}</TotalPrice>
      </OrderSummaryContainer>
    </ConfirmedOrderContainer>
  );
};

export default ConfirmedOrderForm;
