import styled from "@emotion/styled";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import AddTradingDayForm from "../../components/AddTradingDayForm";
import Modal from "../../components/Modal";
import { useApi } from "../../hooks/useApi";

const TradingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const TradingDayList = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 2rem;
`;

const TradingDay = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #ddd;
  margin-bottom: 0.5rem;
  background-color: #fff;
  border-radius: 8px;
`;

const EditButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 1.2rem;
  padding: 0;

  &:hover {
    color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const FloatingButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #0056b3;
  }
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const PaginationButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #0056b3;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const NoTradingDaysMessage = styled.div`
  text-align: center;
  margin-top: 2rem;
  font-size: 1.2rem;
  color: #777;
`;

const TradingPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditMode, setIsEditMode] = useState(false);
  const { addTradingDay, fetchTradingDays } = useApi();
  const [tradingDays, setTradingDays] = useState<{
    page: number;
    totalPages: number;
    totalItems: number;
    items: {
      tradingDayId: string;
      date: string;
      tradingLocationId: string;
      tradingLocationName: string;
      menuId: string;
      start: string;
      close: string;
    }[];
  }>();

  const [selectedTradingDay, setSelectedTradingDay] = useState<{
    tradingDayId: string;
    date: string;
    tradingLocationId: string;
    tradingLocationName: string;
    menuId: string;
    start: string;
    close: string;
  } | null>(null);

  const loadTradingDays = (page: number) => {
    fetchTradingDays(page, 5)
      .then((data) => {
        setTradingDays(data);
      })
      .catch((e) => {
        alert("Failed to fetch trading days");
        console.error(e);
      });
  };

  useEffect(() => {
    loadTradingDays(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleAddTradingDay = async (formData: {
    date: string;
    location: string;
    menu: string;
    start: string;
    close: string;
    tradesPerSlot: number;
  }) => {
    try {
      await addTradingDay(formData);
      loadTradingDays(currentPage);
      setIsModalOpen(false);
    } catch (e) {
      alert("Failed to add trading day");
      console.error(e);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleEditTradingDay = (tradingDay: {
    tradingDayId: string;
    date: string;
    tradingLocationId: string;
    tradingLocationName: string;
    menuId: string;
    start: string;
    close: string;
  }) => {
    setSelectedTradingDay(tradingDay);
    setIsModalOpen(true);
  };

  // Provide default values for editing, ensuring tradesPerSlot is included
  const defaultFormValues = selectedTradingDay
    ? {
        tradingDayId: selectedTradingDay.tradingDayId,
        date: selectedTradingDay.date,
        location: selectedTradingDay.tradingLocationId,
        menu: selectedTradingDay.menuId,
        start: selectedTradingDay.start,
        close: selectedTradingDay.close,
        tradesPerSlot: 12, // Default value for tradesPerSlot
      }
    : undefined;

  return (
    <TradingContainer>
      {tradingDays?.items.length ? (
        <>
          <TradingDayList>
            {tradingDays?.items.map((item) => (
              <TradingDay key={item.tradingDayId}>
                <span>
                  {item.tradingLocationName} -{" "}
                  {DateTime.fromISO(item.date).toLocaleString(
                    DateTime.DATE_MED_WITH_WEEKDAY
                  )}
                </span>
                <EditButton
                  onClick={() => {
                    setIsEditMode(true);
                    handleEditTradingDay(item);
                  }}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </EditButton>
              </TradingDay>
            ))}
          </TradingDayList>
          <PaginationControls>
            <PaginationButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </PaginationButton>
            <PaginationButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === tradingDays?.totalPages}
            >
              Next
            </PaginationButton>
          </PaginationControls>
        </>
      ) : (
        <NoTradingDaysMessage>No trading days available.</NoTradingDaysMessage>
      )}
      <FloatingButton
        onClick={() => {
          setIsEditMode(false);
          setIsModalOpen(true);
        }}
      >
        +
      </FloatingButton>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedTradingDay(null);
        }}
      >
        <AddTradingDayForm
          onSubmit={handleAddTradingDay}
          initialValues={defaultFormValues}
          isEditMode={isEditMode}
        />
      </Modal>
    </TradingContainer>
  );
};

export default TradingPage;
