import styled from "@emotion/styled";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import AddItemForm from "../../components/AddItemForm";
import Modal from "../../components/Modal";
import { useApi } from "../../hooks/useApi";
import { Item } from "../../types";

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  max-width: 1200px;
  width: 100%;
`;

const ItemCard = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ItemTitle = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
`;

const ItemDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #666;
`;

const ItemPrice = styled.p`
  font-size: 1.25rem;
  color: #007bff;
`;

const EditButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 1.2rem;
  padding: 0;
  // margin top and right to 8px
  margin-top: 8px;
  margin-right: 8px;

  &:hover {
    color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const FloatingButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #0056b3;
  }
`;

const ItemsPage: React.FC = () => {
  const { getItems, addItem, updateItem } = useApi();
  const [items, setItems] = useState<Item[]>([]);
  const [currentItem, setCurrentItem] = useState<Item | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await getItems();
        setItems(response);
      } catch (error: any) {
        setError("Failed to fetch items");
      }
    };
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddItem = async (formData: {
    name: string;
    description: string;
    price: string;
  }) => {
    try {
      if (currentItem) {
        await updateItem(currentItem.itemId, formData);
        setItems(
          items.map((item) =>
            item.itemId === currentItem.itemId ? { ...item, ...formData } : item
          )
        );
      } else {
        const newItem = await addItem(formData);
        setItems([...items, { ...formData, itemId: newItem.itemId }]);
      }
      setIsModalOpen(false);
    } catch (error: any) {
      setError("Failed to add/edit item");
    }
  };

  const handleEditClick = (item: Item) => {
    setCurrentItem(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentItem(null);
  };

  return (
    <div>
      {error && <p>{error}</p>}
      <PageContainer>
        <ItemContainer>
          {items.map((item) => (
            <ItemCard key={item.itemId}>
              <ItemTitle>{item.name}</ItemTitle>
              <ItemDescription>{item.description}</ItemDescription>
              <ItemPrice>£{item.price}</ItemPrice>
              <EditButton onClick={() => handleEditClick(item)}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </EditButton>
            </ItemCard>
          ))}
        </ItemContainer>
      </PageContainer>
      <FloatingButton
        onClick={() => {
          setCurrentItem(null);
          setIsModalOpen(true);
        }}
      >
        +
      </FloatingButton>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <AddItemForm onSubmit={handleAddItem} item={currentItem} />
      </Modal>
    </div>
  );
};

export default ItemsPage;
