import styled from "@emotion/styled";
import { faCog, faHome, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import LoadingPizza from "./LoadingPizza";

// Styled component for the navbar
const Navbar = styled.nav`
  background-color: #333;
  padding: 10px 20px;
  display: flex;
  align-items: center;
`;

const HomeIcon = styled.div`
  margin-right: 12px; /* Adjust margin as needed */
`;

const MenuItemsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1; /* Ensures MenuItems stretch to fill remaining space */
`;

const MenuItem = styled(Link)`
  display: block;
  color: white;
  text-decoration: none;
  cursor: pointer;
  margin-left: 15px;

  &:first-of-type {
    margin-left: 0;
  }
`;

const SettingsLink = styled(Link)`
  color: white;
  text-decoration: none;
  cursor: pointer;
  margin-left: auto; /* Pushes SettingsLink and LogoutLink to the right */
  margin-right: 10px; /* Adjusted margin-right for SettingsLink */
`;

const LogoutLink = styled.div`
  color: white;
  text-decoration: none;
  cursor: pointer;
  margin-left: 10px; /* Adjusted margin-left for LogoutLink */
`;

const AdminLayout: React.FC = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (!auth.token) {
      setShouldRedirect(true);
    } else {
      setIsLoading(false);
    }
  }, [auth.token]);

  const handleLogout = () => {
    auth.logout();
    setShouldRedirect(true);
  };

  if (shouldRedirect) {
    return <Navigate to="/login" replace />;
  }

  if (isLoading) {
    return (
      <div>
        <LoadingPizza />
      </div>
    );
  }

  return (
    <div>
      <Navbar>
        <HomeIcon>
          <Link to="/admin" style={{ color: "white", textDecoration: "none" }}>
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </HomeIcon>
        <MenuItemsContainer>
          <MenuItem to="/admin/orders">Orders</MenuItem>
          <MenuItem to="/admin/trading">Trading</MenuItem>
          <MenuItem to="/admin/menu">Menu</MenuItem>
          <MenuItem to="/admin/items">Items</MenuItem>
        </MenuItemsContainer>
        <SettingsLink to="/admin/settings">
          <FontAwesomeIcon icon={faCog} />
        </SettingsLink>
        <LogoutLink onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </LogoutLink>
      </Navbar>

      <Outlet />
    </div>
  );
};

export default AdminLayout;
