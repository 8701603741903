import styled from "@emotion/styled";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useApi } from "../hooks/useApi";
import LoadingPizza from "./LoadingPizza";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  overflow: auto;
`;

const TimeSlotGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 0.5rem;
  width: 100%;
`;

const TimeSlotButton = styled.button<{ full: boolean; past: boolean }>`
  background: ${({ full, past }) => (full || past ? "#ccc" : "#51535a")};
  color: ${({ full, past }) => (full || past ? "#666" : "white")};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: ${({ full, past }) => (full || past ? "not-allowed" : "pointer")};
  text-align: center;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    background: ${({ full, past }) => (full || past ? "#ccc" : "#6b6d74")};
  }
`;

const RemainingSlots = styled.div<{ full: boolean }>`
  font-size: 0.75rem;
  color: ${({ full }) => (full ? "#ff0000" : "#ffffff")};
  margin-top: 0.25rem;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 0.5rem;
`;

const StyledParagraph = styled.p`
  font-size: 1rem;
  color: #333;
  text-align: left;
  margin-bottom: 1rem;

  strong {
    font-weight: bold;
    color: #51535a;
  }
`;

interface TimeSlotSelectionFormProps {
  tradingDayId: string;
  onSelect: (
    tradingSlotId: string,
    timeSlotDate: string,
    remaining: number
  ) => void;
}

const TimeSlotSelectionForm: React.FC<TimeSlotSelectionFormProps> = ({
  tradingDayId,
  onSelect,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { fetchTradingDayTimeSlots } = useApi();
  const [timeSlots, setTimeSlots] = useState<
    {
      tradingSlotId: string;
      start: string;
      end: string;
      capacity: number;
      remaining: number;
      number: number;
    }[]
  >([]);

  useEffect(() => {
    setIsLoading(true);
    fetchTradingDayTimeSlots(tradingDayId).then((data) => {
      setTimeSlots(data);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingDayId]);

  const isPast = (startTime: string) => {
    return DateTime.fromISO(startTime) < DateTime.now();
  };

  if (isLoading) {
    return (
      <div>
        <LoadingPizza />
      </div>
    );
  }

  return (
    <Container>
      <Title>Select A Collection Time</Title>
      <StyledParagraph>
        <strong>Time Slots Explained:</strong> We can cook up to 12 Pizzas every
        15 mins. Each slot shows the number of pizzas still available to order
        in that time period.
      </StyledParagraph>
      <StyledParagraph>
        <strong>
          Want more than the available number of pizzas in a time slot?{" "}
        </strong>
        Easy! All you need to do is find the nearest slot to your preferred time
        with that number of pizzas available
      </StyledParagraph>
      <TimeSlotGrid>
        {timeSlots.map((slot) => (
          <TimeSlotButton
            key={slot.tradingSlotId}
            disabled={slot.remaining <= 0 || isPast(slot.start)}
            full={slot.remaining <= 0}
            past={isPast(slot.start)}
            onClick={() =>
              onSelect(
                slot.tradingSlotId,
                `${DateTime.fromISO(slot.start).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
                )} ${DateTime.fromISO(slot.start).toLocaleString(
                  DateTime.TIME_SIMPLE
                )} - ${DateTime.fromISO(slot.end)
                  .plus({ second: 1 })
                  .toLocaleString(DateTime.TIME_SIMPLE)}`,
                slot.remaining
              )
            }
          >
            {`${DateTime.fromISO(slot.start).toLocaleString(
              DateTime.TIME_SIMPLE
            )} - ${DateTime.fromISO(slot.end)
              .plus({ seconds: 1 })
              .toLocaleString(DateTime.TIME_SIMPLE)}`}
            <RemainingSlots full={slot.remaining <= 0}>
              {slot.remaining > 0 ? `${slot.remaining} pizzas` : "Sold Out"}
            </RemainingSlots>
          </TimeSlotButton>
        ))}
      </TimeSlotGrid>
    </Container>
  );
};

export default TimeSlotSelectionForm;
