import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { useApi } from "../hooks/useApi";
import { Item, Menu, MenuItem } from "../types";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const TextInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ItemsTitle = styled.h3`
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
`;

const ItemsContainer = styled.div`
  display: grid;
  gap: 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 300px;
  overflow-y: auto;
`;

const ItemCard = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: ${({ isSelected }) => (isSelected ? "row" : "column")};
  justify-content: ${({ isSelected }) =>
    isSelected ? "space-between" : "center"};
  align-items: center;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: ${({ isSelected }) => (isSelected ? "#e0f7fa" : "#fff")};
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0f7fa;
  }
`;

const ItemName = styled.p`
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
`;

const ItemPrice = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: #666;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  align-self: center;

  &:hover {
    background-color: #0056b3;
  }
`;

interface EditMenuFormProps {
  items: Item[];
  onSubmit: (menu: Menu) => void;
  menuId: string;
  onClose: () => void;
}

const EditMenuForm: React.FC<EditMenuFormProps> = ({
  items,
  onSubmit,
  menuId,
  onClose,
}) => {
  const { getMenu, updateMenu } = useApi();
  const [name, setName] = useState("");
  const [selectedItems, setSelectedItems] = useState<MenuItem[]>([]);
  const [availableItems, setAvailableItems] = useState<Item[]>([]);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const menu = await getMenu(menuId);
        setName(menu.name);
        setSelectedItems(menu.items);
      } catch (error: any) {
        console.error("Failed to fetch menu", error);
      }
    };
    fetchMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId]);

  useEffect(() => {
    const selectedIds = new Set(selectedItems.map((item) => item.itemId));
    setAvailableItems(items.filter((item) => !selectedIds.has(item.itemId)));
  }, [items, selectedItems]);

  const handleItemChange = (item: Item) => {
    if (selectedItems.some((i) => i.itemId === item.itemId)) {
      setSelectedItems((prevItems) =>
        prevItems.filter((i) => i.itemId !== item.itemId)
      );
      setAvailableItems((prevItems) => [...prevItems, item]);
    } else {
      const newItem: MenuItem = { ...item, order: selectedItems.length };
      setSelectedItems((prevItems) => [...prevItems, newItem]);
      setAvailableItems((prevItems) =>
        prevItems.filter((i) => i.itemId !== item.itemId)
      );
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(selectedItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedItems(
      items.map((item, index) => ({
        ...item,
        order: index,
      }))
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const updatedMenu: Menu = {
      menuId,
      name,
      items: selectedItems.map((item) => ({
        itemId: item.itemId,
        name: item.name,
        description: item.description,
        price: item.price,
        order: item.order,
      })),
    };
    await updateMenu(menuId, {
      name: updatedMenu.name,
      items: updatedMenu.items.map((i) => ({
        itemId: i.itemId,
        order: i.order,
      })),
    });
    onSubmit(updatedMenu);
    onClose();
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <InputContainer>
        <Label>Menu Name:</Label>
        <TextInput
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </InputContainer>
      <ItemsSection>
        <ItemsTitle>Selected Items</ItemsTitle>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="selectedItems">
            {(provided) => (
              <ListContainer
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {selectedItems.map((item, index) => (
                  <Draggable
                    key={item.itemId}
                    draggableId={item.itemId}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ItemCard
                          isSelected={true}
                          onClick={() => handleItemChange(item)}
                        >
                          <ItemName>{item.name}</ItemName>
                          <ItemPrice>£{item.price}</ItemPrice>
                        </ItemCard>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ListContainer>
            )}
          </Droppable>
        </DragDropContext>
      </ItemsSection>
      <ItemsSection>
        <ItemsTitle>Available Items</ItemsTitle>
        <ItemsContainer>
          {availableItems.map((item) => (
            <ItemCard
              key={item.itemId}
              isSelected={false}
              onClick={() => handleItemChange(item)}
            >
              <ItemName>{item.name}</ItemName>
              <ItemPrice>£{item.price}</ItemPrice>
            </ItemCard>
          ))}
        </ItemsContainer>
      </ItemsSection>
      <SubmitButton type="submit">Update Menu</SubmitButton>
    </FormContainer>
  );
};

export default EditMenuForm;
