/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import {
  faCircleChevronDown,
  faCircleChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useApi } from "../hooks/useApi";

const FormWrapper = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 360px;
`;

const Title = styled.h1`
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.25rem;
  color: #333;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #666;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 0.875rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #51535a;
  color: white;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #6b6d74;
  }
`;

const TimeSlotContainer = styled.div`
  margin-top: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;

  margin-bottom: 1rem;
`;

const TimeSlotHeader = styled.div`
  background-color: #51535a;
  color: white;
  padding: 0.75rem 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #6b6d74;
  }
`;

const TimeSlotContent = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
  overflow: hidden;
  overflow-y: auto;
  transition: max-height 0.3s ease;
`;

const TimeSlotList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const TimeSlotItem = styled.li`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  &:last-of-type {
    border-bottom: none;
  }
`;

const TimeSlotDetails = styled.span`
  font-weight: bold;
  color: #51535a;
`;

const CapacityInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CapacitySelect = styled.select`
  background-color: #e0e0e0;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  color: #666;
  border: 1px solid #ccc;
  appearance: none;
  cursor: pointer;
`;

const LabelText = styled.span`
  margin-right: 0.5rem;
`;

const RemainingText = styled.span`
  font-size: 0.75rem;
  color: #888;
  margin-top: 0.2rem;
`;

interface AddTradingDayFormProps {
  onSubmit: (formData: {
    date: string;
    location: string;
    menu: string;
    start: string;
    close: string;
    tradesPerSlot: number;
  }) => void;
  initialValues?: {
    tradingDayId: string;
    date: string;
    location: string;
    menu: string;
    start: string;
    close: string;
    tradesPerSlot: number;
  };
  isEditMode: boolean;
}

const AddTradingDayForm: React.FC<AddTradingDayFormProps> = ({
  onSubmit,
  initialValues,
  isEditMode,
}) => {
  const { fetchTradingLocations, fetchMenus, fetchTradingDayTimeSlots } =
    useApi();
  const [locations, setLocations] = useState<
    { tradingLocationId: string; name: string }[]
  >([]);
  const [menus, setMenus] = useState<{ menuId: string; name: string }[]>([]);
  const [timeSlots, setTimeSlots] = useState<
    {
      tradingSlotId: string;
      start: string;
      end: string;
      capacity: number;
      remaining: number;
      number: number;
    }[]
  >([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchTradingLocations()
      .then(setLocations)
      .catch((e: any) => {
        console.error("Failed to fetch trading locations:", e);
      });

    fetchMenus()
      .then(setMenus)
      .catch((e: any) => {
        console.error("Failed to fetch menus:", e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultOpenTime = new Date();
  defaultOpenTime.setHours(17, 0, 0, 0); // 5pm today
  const defaultCloseTime = new Date();
  defaultCloseTime.setHours(20, 0, 0, 0); // 8pm today

  const [formData, setFormData] = useState<{
    date: string;
    location: string;
    menu: string;
    start: string;
    close: string;
    tradesPerSlot: number;
  }>({
    date: new Date().toISOString().split("T")[0] + "T00:00:00.000Z", // Set date to midnight
    location: "",
    menu: "",
    start: defaultOpenTime.toISOString(),
    close: defaultCloseTime.toISOString(),
    tradesPerSlot: 12,
    ...initialValues,
  });

  useEffect(() => {
    if (initialValues) {
      setFormData({
        ...initialValues,
        date:
          new Date(initialValues.date).toISOString().split("T")[0] +
          "T00:00:00.000Z",
        start: new Date(initialValues.start).toISOString(),
        close: new Date(initialValues.close).toISOString(),
      });

      fetchTradingDayTimeSlots(initialValues.tradingDayId)
        .then(setTimeSlots)
        .catch((e: any) => {
          console.error("Failed to fetch trading day time slots:", e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "tradesPerSlot" ? parseInt(value) : value,
    }));
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = new Date(date);
      formattedDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
      setFormData((prev) => {
        const newStart = new Date(formattedDate);
        newStart.setHours(new Date(prev.start).getHours());
        newStart.setMinutes(new Date(prev.start).getMinutes());

        const newClose = new Date(formattedDate);
        newClose.setHours(new Date(prev.close).getHours());
        newClose.setMinutes(new Date(prev.close).getMinutes());

        return {
          ...prev,
          date: formattedDate.toISOString(),
          start: newStart.toISOString(),
          close: newClose.toISOString(),
        };
      });
    }
  };

  const handleTimeChange =
    (field: "start" | "close") => (time: Date | null) => {
      if (time) {
        setFormData((prev) => {
          const newDateTime = new Date(prev.date);
          newDateTime.setHours(time.getHours(), time.getMinutes(), 0, 0);
          return {
            ...prev,
            [field]: newDateTime.toISOString(),
          };
        });
      }
    };

  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLocation = e.target.value;
    setFormData((prev) => ({
      ...prev,
      location: selectedLocation,
    }));

    fetchMenus()
      .then(setMenus)
      .catch((e: any) => {
        console.error("Failed to fetch menus:", e);
      });
  };

  const handleMenuChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMenu = e.target.value;
    setFormData((prev) => ({
      ...prev,
      menu: selectedMenu,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <FormWrapper>
      <Title>{isEditMode ? "Edit Trading Day" : "Add Trading Day"}</Title>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Date</Label>
          <DatePicker
            selected={new Date(formData.date)}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            className="form-control"
          />
        </FormGroup>
        <FormGroup>
          <Label>Location</Label>
          <Select
            name="location"
            value={formData.location}
            onChange={handleLocationChange}
          >
            <option value="">Select Location</option>
            {locations.map((loc) => (
              <option key={loc.tradingLocationId} value={loc.tradingLocationId}>
                {loc.name}
              </option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label>Menu</Label>
          <Select name="menu" value={formData.menu} onChange={handleMenuChange}>
            <option value="">Select Menu</option>
            {menus.map((menu) => (
              <option key={menu.menuId} value={menu.menuId}>
                {menu.name}
              </option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label>Start Time</Label>
          <DatePicker
            selected={new Date(formData.start)}
            onChange={handleTimeChange("start")}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
            className="form-control"
          />
        </FormGroup>
        <FormGroup>
          <Label>Close Time</Label>
          <DatePicker
            selected={new Date(formData.close)}
            onChange={handleTimeChange("close")}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
            className="form-control"
          />
        </FormGroup>

        {!isEditMode && (
          <FormGroup>
            <Label>Trades Per Slot</Label>
            <input
              type="number"
              name="tradesPerSlot"
              value={formData.tradesPerSlot}
              onChange={handleChange}
              className="form-control"
              min={1}
            />{" "}
          </FormGroup>
        )}

        {isEditMode && timeSlots.length > 0 && (
          <TimeSlotContainer>
            <TimeSlotHeader onClick={() => setIsOpen(!isOpen)}>
              Time Slots
              <FontAwesomeIcon
                icon={isOpen ? faCircleChevronUp : faCircleChevronDown}
              />
            </TimeSlotHeader>
            <TimeSlotContent isOpen={isOpen}>
              <TimeSlotList>
                {timeSlots.map((slot) => (
                  <TimeSlotItem key={slot.tradingSlotId}>
                    <TimeSlotDetails>
                      {DateTime.fromISO(slot.start).toFormat("HH:mm")} -{" "}
                      {DateTime.fromISO(slot.end)
                        .plus({ second: 1 })
                        .toFormat("HH:mm")}
                    </TimeSlotDetails>
                    <CapacityInfoWrapper>
                      <div>
                        <LabelText>Capacity:</LabelText>
                        <CapacitySelect
                          name="capacity"
                          value={slot.capacity}
                          onChange={(e) => {
                            // Handle capacity change logic here
                          }}
                        >
                          {[...Array(30)].map((_, i) => (
                            <option
                              key={i + 1}
                              value={i + 1}
                              disabled={i + 1 < slot.remaining}
                            >
                              {i + 1}
                            </option>
                          ))}
                        </CapacitySelect>
                      </div>
                      <RemainingText>Remaining: {slot.remaining}</RemainingText>
                    </CapacityInfoWrapper>
                  </TimeSlotItem>
                ))}
              </TimeSlotList>
            </TimeSlotContent>
          </TimeSlotContainer>
        )}
        <Button type="submit">
          {isEditMode ? "Update Trading Day" : "Add Trading Day"}
        </Button>
      </form>
    </FormWrapper>
  );
};

export default AddTradingDayForm;
