/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import React, { useState } from "react";

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;
  margin: 2rem;
`;

const FormWrapper = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 360px;
`;

const Title = styled.h1`
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.25rem;
  color: #333;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #666;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 0.875rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 0.875rem;
`;

const Option = styled.option``;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const AddUserForm: React.FC<{ onSubmit: (formData: FormData) => void }> = ({
  onSubmit,
}) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    role: "USER",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("username", formData.username);
    formDataObj.append("password", formData.password);
    formDataObj.append("role", formData.role);
    onSubmit(formDataObj);
    setFormData({
      username: "",
      password: "",
      role: "USER",
    });
  };

  return (
    <FormContainer>
      <FormWrapper>
        <Title>Add User</Title>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="username">Username:</Label>
            <Input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password:</Label>
            <Input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="role">Role:</Label>
            <Select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
            >
              <Option value="ADMIN">ADMIN</Option>
              <Option value="USER">USER</Option>
            </Select>
          </FormGroup>
          <Button type="submit">Add User</Button>
        </form>
      </FormWrapper>
    </FormContainer>
  );
};

export default AddUserForm;
