import styled from "@emotion/styled";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import AddMenuForm from "../../components/AddMenuForm";
import EditMenuForm from "../../components/EditMenuForm";
import Modal from "../../components/Modal";
import { useApi } from "../../hooks/useApi";
import { Item, Menu } from "../../types";

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const MenuList = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 2rem;
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #ddd;
  margin-bottom: 0.5rem;
  background-color: #fff;
  border-radius: 8px;
`;

const EditButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 1.2rem;
  padding: 0;

  &:hover {
    color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const FloatingButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #0056b3;
  }
`;

const MenuPage: React.FC = () => {
  const { getItems, fetchMenus } = useApi();
  const [items, setItems] = useState<Item[]>([]);
  const [menus, setMenus] = useState<Menu[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMenuId, setCurrentMenuId] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [itemsResponse, menusResponse] = await Promise.all([
          getItems(),
          fetchMenus(),
        ]);
        setItems(itemsResponse);
        setMenus(menusResponse);
      } catch (error: any) {
        console.error("Failed to fetch data", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddMenu = (menu: Menu) => {
    setMenus([...menus, menu]);
    setIsModalOpen(false);
  };

  const handleEditMenu = (updatedMenu: Menu) => {
    setMenus(
      menus.map((menu) =>
        menu.menuId === updatedMenu.menuId ? updatedMenu : menu
      )
    );
    setIsModalOpen(false);
  };

  const handleOpenModal = (menuId: string | null = null) => {
    setCurrentMenuId(menuId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentMenuId(null);
  };

  return (
    <MenuContainer>
      <MenuList>
        {menus.map((menu) => (
          <MenuItem key={menu.menuId}>
            <span>{menu.name}</span>
            <EditButton onClick={() => handleOpenModal(menu.menuId)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </EditButton>
          </MenuItem>
        ))}
      </MenuList>
      <FloatingButton onClick={() => handleOpenModal(null)}>+</FloatingButton>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {currentMenuId ? (
          <EditMenuForm
            items={items}
            onSubmit={handleEditMenu}
            menuId={currentMenuId}
            onClose={handleCloseModal}
          />
        ) : (
          <AddMenuForm items={items} onSubmit={handleAddMenu} menu={null} />
        )}
      </Modal>
    </MenuContainer>
  );
};

export default MenuPage;
