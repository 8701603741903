import styled from "@emotion/styled";
import { enGB } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingPizza from "../../components/LoadingPizza";
import Modal from "../../components/Modal";
import { useApi } from "../../hooks/useApi";

registerLocale("en-GB", enGB);

const OrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const SubNavBar = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: #f8f9fa;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const DatePickerContainer = styled.div`
  width: 100%;
  max-width: 200px;
  margin-bottom: 0.5rem;
  position: relative;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .custom-date-picker {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.875rem;
  }

  .custom-calendar {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .custom-day {
    font-size: 0.875rem;
  }
`;

const FilterInputsContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  @media (max-width: 768px) {
    gap: 0.5rem;
    flex-direction: column;
    justify-content: center;
  }
`;

const FilterInput = styled.input`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
    flex: 1;
  }
`;

const FilterSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  max-width: 200px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const FilterButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #0056b3;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ClearButton = styled.button`
  background: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 0.5rem;

  &:hover {
    background: #c82333;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

const TotalsContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin-top: 0.25rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: row;
    text-align: center;
    gap: 0.5rem;
    padding: 1rem;
    width: 90%;
  }
`;

const OrdersList = styled.div`
  width: 100%;
  max-width: 800px;
  margin-top: 1rem;
`;

const OrderItem = styled.div`
  padding: 0.5rem;
  border: 1px solid #ddd;
  margin-bottom: 0.5rem;
  background-color: #fff;
  border-radius: 8px;
  font-size: 0.875rem;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const OrderItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

const OrderItemLabel = styled.span`
  font-weight: bold;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const PaginationButton = styled.button`
  background: #51535a;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: #6b6d74;
  }
`;

const LoadingBox = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 1rem;
  margin-top: 1rem;
  text-align: center;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  font-size: 0.875rem;
`;

const ModalContent = styled.div`
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;

  h2 {
    margin-top: 0;
  }

  p {
    margin: 0.5rem 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0 0;
  }

  li {
    margin-bottom: 0.5rem;
  }
`;

const NoResultsBox = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 1rem;
  margin-top: 1rem;
  text-align: center;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  font-size: 0.875rem;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

const StatusDropdown = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 0.5rem;
  flex-grow: 1;
`;

const UpdateStatusButton = styled.button`
  background: #28a745;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #218838;
  }
`;

const OrdersPage: React.FC = () => {
  const {
    fetchTradingDaysList,
    fetchOrdersOrdersByTradingDay,
    fetchOrdersCountByTradingDay,
    updateOrderStatus,
  } = useApi();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [availableDates, setAvailableDates] = useState<
    { date: Date; tradingDayId: string }[]
  >([]);
  const [orders, setOrders] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedTradingDayId, setSelectedTradingDayId] = useState<
    string | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any | null>(null);
  const [orderNumberFilter, setOrderNumberFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState<string>("all");
  const [orderCount, setOrderCount] = useState<{
    orders: number;
    pizzas: number;
  } | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>("collected");

  useEffect(() => {
    const loadTradingDays = async () => {
      const tradingDays = await fetchTradingDaysList();
      const dates = tradingDays.map(
        (day: { date: string; tradingDayId: string }) => ({
          date: new Date(day.date),
          tradingDayId: day.tradingDayId,
        })
      );
      setAvailableDates(dates);
    };

    loadTradingDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = async (date: Date | null) => {
    setSelectedDate(date);
    setOrderNumberFilter("");
    setNameFilter("");
    setCurrentPage(1);
    const selectedDay = availableDates.find(
      (d) =>
        d.date.getFullYear() === date?.getFullYear() &&
        d.date.getMonth() === date?.getMonth() &&
        d.date.getDate() === date?.getDate()
    );
    if (selectedDay) {
      setSelectedTradingDayId(selectedDay.tradingDayId);
      setIsLoading(true);
      const ordersData = await fetchOrders(
        selectedDay.tradingDayId,
        1,
        "",
        "",
        statusFilter
      );
      const orderCountData = await fetchOrdersCountByTradingDay(
        selectedDay.tradingDayId,
        statusFilter
      );
      setOrders(ordersData.items);
      setTotalPages(ordersData.totalPages);
      setOrderCount(orderCountData);
      setIsLoading(false);
    }
  };

  const fetchOrders = async (
    tradingDayId: string,
    page: number,
    orderNumber?: string,
    name?: string,
    status?: string
  ) => {
    const response = await fetchOrdersOrdersByTradingDay(
      tradingDayId,
      page,
      10,
      name,
      orderNumber,
      status
    );
    return response;
  };

  const handlePageChange = async (newPage: number) => {
    if (selectedTradingDayId) {
      setIsLoading(true);
      const ordersData = await fetchOrders(
        selectedTradingDayId,
        newPage,
        orderNumberFilter,
        nameFilter,
        statusFilter
      );
      setOrders(ordersData.items);
      setCurrentPage(newPage);
      setIsLoading(false);
    }
  };

  const handleOrderClick = (order: any) => {
    setSelectedOrder(order);
    setSelectedStatus(order.status || "collected");
    setIsModalOpen(true);
  };

  const handleOrderNumberFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderNumberFilter(event.target.value);
  };

  const handleNameFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNameFilter(event.target.value);
  };

  const handleStatusFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setStatusFilter(event.target.value);
  };

  const handleFilterSubmit = async () => {
    if (selectedTradingDayId) {
      setIsLoading(true);
      const ordersData = await fetchOrders(
        selectedTradingDayId,
        1,
        orderNumberFilter,
        nameFilter,
        statusFilter
      );
      const orderCountData = await fetchOrdersCountByTradingDay(
        selectedTradingDayId,
        statusFilter
      );
      setOrders(ordersData.items);
      setOrderCount(orderCountData);
      setTotalPages(ordersData.totalPages);
      setCurrentPage(1);
      setIsLoading(false);
    }
  };

  const handleClearFilters = async () => {
    setOrderNumberFilter("");
    setNameFilter("");
    setStatusFilter("open");
    if (selectedTradingDayId) {
      setIsLoading(true);
      const ordersData = await fetchOrders(
        selectedTradingDayId,
        1,
        "",
        "",
        "open"
      );
      setOrders(ordersData.items);
      setTotalPages(ordersData.totalPages);
      setCurrentPage(1);
      setIsLoading(false);
    }
  };

  const isDateSelectable = (date: Date) => {
    return availableDates.some(
      (availableDate) =>
        date.getFullYear() === availableDate.date.getFullYear() &&
        date.getMonth() === availableDate.date.getMonth() &&
        date.getDate() === availableDate.date.getDate()
    );
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
    // Refetch orders to refresh the list
    if (selectedTradingDayId) {
      setIsLoading(true);
      const ordersData = await fetchOrders(
        selectedTradingDayId,
        currentPage,
        orderNumberFilter,
        nameFilter,
        statusFilter
      );
      setOrders(ordersData.items);
      setIsLoading(false);
    }
  };

  const handleStatusChange = async () => {
    if (selectedOrder && selectedStatus) {
      setIsLoading(true);
      await updateOrderStatus(selectedOrder.orderNumber, selectedStatus);
      setIsLoading(false);
      await closeModal();
    }
  };

  return (
    <OrdersContainer>
      <SubNavBar>
        {/* <form onSubmit={handleFilterSubmit}> */}
        <DatePickerContainer>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            placeholderText="Select a trading day"
            filterDate={isDateSelectable}
            highlightDates={availableDates.map((d) => d.date)}
            locale="en-GB"
            className="custom-date-picker"
            calendarClassName="custom-calendar"
            dayClassName={() => "custom-day"}
          />
        </DatePickerContainer>
        <FilterInputsContainer>
          <FilterInput
            type="text"
            placeholder="Order Number"
            value={orderNumberFilter}
            onChange={handleOrderNumberFilterChange}
          />
          <FilterInput
            type="text"
            placeholder="Name"
            value={nameFilter}
            onChange={handleNameFilterChange}
          />
          <FilterSelect
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <option value="all">All</option>
            <option value="cancelled">Cancelled</option>
            <option value="collected">Collected</option>
            <option value="open">Open</option>
          </FilterSelect>
          <ClearButton onClick={handleClearFilters}>Clear</ClearButton>
          <FilterButton onClick={handleFilterSubmit}>Filter</FilterButton>
        </FilterInputsContainer>
        {/* </form> */}
      </SubNavBar>
      {selectedDate && (
        <>
          {isLoading ? (
            <LoadingBox>
              <LoadingPizza />
            </LoadingBox>
          ) : orders.length > 0 ? (
            <>
              <TotalsContainer>
                <div>
                  <p>
                    <strong>Total Orders:</strong> {orderCount?.orders}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Total Pizzas:</strong> {orderCount?.pizzas}
                  </p>
                </div>
              </TotalsContainer>
              <OrdersList>
                {orders.map((order) => (
                  <OrderItem
                    key={order.orderNumber}
                    onClick={() => handleOrderClick(order)}
                  >
                    <OrderItemRow>
                      <OrderItemLabel>Order Number:</OrderItemLabel>
                      <span>{order.orderNumber}</span>
                    </OrderItemRow>
                    <OrderItemRow>
                      <OrderItemLabel>Name:</OrderItemLabel>
                      <span>{order.name}</span>
                    </OrderItemRow>
                    <OrderItemRow>
                      <OrderItemLabel>Total:</OrderItemLabel>
                      <span>£{order.total}</span>
                    </OrderItemRow>
                    <OrderItemRow>
                      <OrderItemLabel>Time Slot:</OrderItemLabel>
                      <span>{order.timeSlot}</span>
                    </OrderItemRow>
                    <OrderItemRow>
                      <OrderItemLabel>Status:</OrderItemLabel>
                      <span>{order.status}</span>
                    </OrderItemRow>
                  </OrderItem>
                ))}
              </OrdersList>
              <PaginationContainer>
                <PaginationButton
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </PaginationButton>
                <PaginationButton
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </PaginationButton>
              </PaginationContainer>
            </>
          ) : (
            <NoResultsBox>No results found</NoResultsBox>
          )}
        </>
      )}
      {isModalOpen && selectedOrder && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ModalContent>
            <h2>Order Details</h2>
            <p>
              <strong>Order Number:</strong> {selectedOrder.orderNumber}
            </p>
            <p>
              <strong>Time Slot:</strong> {selectedOrder.timeSlot}
            </p>
            <p>
              <strong>Name:</strong> {selectedOrder.name}
            </p>
            <p>
              <strong>Email:</strong> {selectedOrder.email}
            </p>
            <p>
              <strong>Phone:</strong> {selectedOrder.phone}
            </p>
            <p>
              <strong>Total:</strong> £{selectedOrder.total}
            </p>
            <p>
              <strong>Notes:</strong> {selectedOrder.notes}
            </p>
            <p>
              <strong>Status:</strong> {selectedOrder.status}
            </p>
            <h3>Items:</h3>
            <ul>
              {selectedOrder.items.map((item: any) => (
                <li key={item.name}>
                  {item.quantity} x {item.name} @ £{item.price}
                </li>
              ))}
            </ul>
            <StatusContainer>
              <StatusDropdown
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                }}
              >
                <option value="cancelled">Cancelled</option>
                <option value="collected">Collected</option>
                <option value="open">Open</option>
              </StatusDropdown>
              <UpdateStatusButton onClick={handleStatusChange}>
                Update Status
              </UpdateStatusButton>
            </StatusContainer>
          </ModalContent>
        </Modal>
      )}
    </OrdersContainer>
  );
};

export default OrdersPage;
