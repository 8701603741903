import React, { createContext, useCallback, useEffect, useState } from "react";
import { useApi } from "../hooks/useApi";
import { User } from "../types";

interface AuthContextType {
  token: string | null;
  user: User | null;
  handleLogin: (username: string, password: string) => Promise<void>;
  logout: () => void;
  isAuthenticated: () => boolean;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const { login, userInfo: getUserInfo } = useApi();

  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await getUserInfo(); // Pass token to get user info
      setUser(response); // Assuming API response structure
    } catch (error) {
      // Handle error fetching user info
      console.error("Error fetching user info:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      setToken(storedToken);
      fetchUserInfo();
    }
  }, [fetchUserInfo]);

  const handleLogin = async (username: string, password: string) => {
    try {
      const response = await login(username, password);
      const authToken = response.token;
      localStorage.setItem("authToken", authToken);
      setToken(authToken);
      fetchUserInfo(); // Fetch user info after successful login
    } catch (error) {
      // Handle login error
      console.error("Login error:", error);
    }
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    setToken(null);
    setUser(null); // Clear user info on logout
  };

  const isAuthenticated = () => {
    return !!localStorage.getItem("authToken");
  };

  return (
    <AuthContext.Provider
      value={{ token, user, handleLogin, logout, isAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};
