import styled from "@emotion/styled";
import React from "react";
import AddUserForm from "../../components/AddUserForm";
import ChangePasswordForm from "../../components/ChangePasswordForm";
import { useApi } from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

const PageContainer = styled.div<{ isAdmin: boolean }>`
  display: grid;
  grid-template-columns: ${({ isAdmin }) => (isAdmin ? "1fr 1fr" : "1fr")};
  gap: 2rem;
  padding: 2rem;
  margin: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 1rem;
  }
`;

const SectionTitle = styled.h2`
  grid-column: span 2;
  text-align: center;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    grid-column: span 1;
  }
`;

const SettingsPage: React.FC = () => {
  const { user } = useAuth();
  const { addUser } = useApi();

  const handleAddUser = (formData: FormData) => {
    addUser(formData)
      .then(() => {
        alert("User added successfully!");
      })
      .catch((error) => {
        alert("Failed to add user: " + error.message);
      });
  };

  return (
    <PageWrapper>
      <PageContainer isAdmin={user?.role === "ADMIN"}>
        <SectionTitle>Settings</SectionTitle>
        <ChangePasswordForm />
        {user?.role === "ADMIN" && <AddUserForm onSubmit={handleAddUser} />}
      </PageContainer>
    </PageWrapper>
  );
};

export default SettingsPage;
